import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"

import ReactMarkdown from "react-markdown"
import { ContentfulOpenCallsPageQuery } from "../../../types/gatsby-graphql"
import Container from "../../components/Container/Container"
import Seo from "../../components/Seo/seo"
import Icon from "../../Icon"

import * as styles from "./OpenCall.module.scss"

const OpenCall = () => {
  const data: ContentfulOpenCallsPageQuery = useStaticQuery(graphql`
    query ContentfulOpenCallsPage {
      contentfulOpenCallsPage {
        title
        description {
          description
        }
        image {
          title
          file {
            url
          }
        }
      }
    }
  `)

  useEffect(() => {
    const main = document.querySelector("main") as unknown as HTMLElement
    main.setAttribute("style", "height: 100%;")
  }, [])

  return (
    <>
      <Seo title="open call" />
      <Container className={styles.openCallContainer}>
        <Icon name="purpleSquares" className={styles.openCallBackground} />
        <div className={styles.openCallImagePhone}>
          <img
            src={data.contentfulOpenCallsPage?.image?.file?.url as string}
            alt={data.contentfulOpenCallsPage?.image?.title as string}
          />
        </div>
        <div className={styles.openCall}>
          <div className={styles.openCallContent}>
            <h1>{data?.contentfulOpenCallsPage?.title}</h1>
            <ReactMarkdown>
              {
                data?.contentfulOpenCallsPage?.description
                  ?.description as string
              }
            </ReactMarkdown>

            <Link to="/sign-up">SIGN UP</Link>
          </div>
          <div className={styles.openCallImage}>
            <img
              src={data.contentfulOpenCallsPage?.image?.file?.url as string}
              alt={data.contentfulOpenCallsPage?.image?.title as string}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default OpenCall
